import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How 1 Tweet Turned Into $1.5M`}</h1>
    <p><strong parentName="p">{`DAY 6:`}</strong>{` `}<br />{` We spent all day yesterday lining up our shots with warm and cold leads.`}</p>
    <p>{`Then...crickets. Ok technically, 40% open rate, but only 10 replies (out of 40) so far.`}</p>
    <p>{`Well not exactly. But the responses were rolling in slowly. So I messaged Ben:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "519px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.249999999999996%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuUlEQVQY042POw6DQAxEuf8lcoB0UZQmd6AiEgVR+IiFXf4gxLIgkQf5VBRMYY0tz3hsnc7Xh+PYtu26blEUSqmmaYYNXddR+76f53nZg3W53eM49ryn//KllFmW0VZVVZYlLUZUrfW+WA+D3CCESNNU/BCGoYhXEkVRkiQQJRULpGvblgN1XVvGmDzPYZzijhkNIRkSeNygN9B+KpNpmiDsrGJseBV7jP8bywF8xSjRE4l4QRDAj4jfUltUgbbvyXwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan Tweet Faster Response",
          "title": "Shaan Tweet Faster Response",
          "src": "/static/a3a53c71eeef6743bbf06c6700845b30/d6ae3/shaan-tweet-faster-response.png",
          "srcSet": ["/static/a3a53c71eeef6743bbf06c6700845b30/d9f49/shaan-tweet-faster-response.png 400w", "/static/a3a53c71eeef6743bbf06c6700845b30/d6ae3/shaan-tweet-faster-response.png 519w"],
          "sizes": "(max-width: 519px) 100vw, 519px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`They say "necessity is the mother of invention". That sounds cool, but for me it's more like "fear of humiliation is creativity's drunk uncle"`}</p>
    <p>{`I promised the all access pass (you!) that we'd raise this whole thing in a month. If I'm getting ˜10 replies a day, that's just too slow. So, we needed to manufacture momentum. Like...Now.`}</p>
    <p>{`I had seen some other rolling funds getting buzz on twitter. @shl - the founder of gumroad had been really getting a lot of buzz. He has like 100k followers, so that helps..but I figure hey it's worth a shot.`}</p>
    <p>{`Pushed send on this bad boy:
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "541px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "93.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXklEQVQ4y41TyXLTQBDVp3LnyJGtioIjX8GRA1AsRS5woHIJwbuk0WZLiRTbsmLJ0oxmRitPMkmgDCFdXarWdL/pntfdCm/anLHxZHLy/TTP87Ztm6Zp7yZKtKO5EJTSOElkUXTgO2jddKLcu//g1YevC9fTiLFcrQGue4FvH3EoOK+qzql8fPfWshzKRZJSWZSHtTX9M/Zv2YNvyjZtJ7i4MAzDcRw/8Ofzha4TTdc974wQw3bmmqbbtgOdTKeIMQxTVdU4jjswIWQ2UxENt2lZw9HIdb0wDDWdOA6ums8Qq2mwRuPxYDhkjF2TqkxnwKqdztTBYIhsuBt4TdMmkymyEcM0egESVSAAyjnvwGUvVVUVRUEpA1UwcIIPDC44nDCklEJKnAshkBzxHXjhuuv12vM827aDIHCR1PMWC9f3fdO0Vj3//+zzlNhj3RzMCJmfm26gOWeWF6SUcSHSjDKWH2KQP01T1KicjtXjkx/EcjZRHEZbaLRNbh8yzNLFconKlfen1uvj6diP/bx1s9qD0nqR1U5ar/K6H6e/ZN5sNvgqz7/YTz/rT47IoyPy+Mh4+El99i14qVUvRuKNV7Z/ovcFJUkCUsCisrzchUm2zfLLlIVxhlHHfJdNp9X/FkRJUywFy3puYKOBcbLLKE12qZTFf8As57mQHbmywHoJdDvnXMoMDiFvduNgsTrwue9H213S5adrMJ5mYJtxvok2y1WIG27L3PbLiZn6jcyqKKtrfsrlZZXQ5mq9UFp91UkFBoYTFe5XHEeo/9dvjVa1RRBdg/GLp5XY9r7ynzv0MgTRWCWnAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan Tweet Poll Fund",
          "title": "Shaan Tweet Poll Fund",
          "src": "/static/44b3ef7a779aa2998b935b1b22b3794a/a010c/shaan-tweet-poll-fund.png",
          "srcSet": ["/static/44b3ef7a779aa2998b935b1b22b3794a/d9f49/shaan-tweet-poll-fund.png 400w", "/static/44b3ef7a779aa2998b935b1b22b3794a/a010c/shaan-tweet-poll-fund.png 541w"],
          "sizes": "(max-width: 541px) 100vw, 541px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Within 10 minutes, the floodgates opened.`}</p>
    <p>{`Now I'm not going to spend too much time talking about this, because:`}</p>
    <ol>
      <li parentName="ol">{`I didn't predict this would work, I was just firing bullets`}</li>
      <li parentName="ol">{`It's not like most people could recreate a tweet that spreads virally`}</li>
    </ol>
    <p>{`Yeah, yeah, I know what you’re thinking...he has 19,000 followers and I have nowhere near that! Does that matter? Hell yea, but not as much as you think.`}</p>
    <p>{`What matters is what we did with all the inbound and turned potential investors from interested → closed, fast.`}</p>
    <p>{`  `}<strong parentName="p">{`Let's walk through the script I used to close over $1.5M in 24 hours.`}</strong></p>
    <p>{`  `}<strong parentName="p">{`Step 1 - Turn the tables`}</strong></p>
    <p>{`Every DM we got was something like: "hey I'm interested, do you have a pitch deck? Or some version of "can you send me something to convince me to invest?"`}</p>
    <p>{` But these aren't just DMs, blushie - this is jiu jitsu.`}</p>
    <p>{` So first step is to reverse the roles. Instead of qualifying myself as being worthy of their investment, I asked them to qualify themselves (classic sales technique)`}</p>
    <p>{`So how’d we do that?`}</p>
    <p>{`Nicely asked them to provide more context on where they’re from and what they do.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdElEQVQoz3WPS0/CQBSF+U1ujQlqNCEujK+4c+XCqAt3Jsa488foAlEIYKJRFAOI5dEKiFSt1PCs8mjn0Zk+HGgkmMDJlzM3d+bM3HHJtaZUqZcrtWQ2xwmFRIp/SKZ6RVrg+LxQfGNkC6VSuUIN0/4vV7ogJvgXuVqPcTwLxFPCfZx74vPRx1Q6V3yVvhjFD1mqNEaEWx21+dNmXldaDIB0hHWdUGaUGoZpORBqWLY9TC9s9VfrT86VOiEQYxVADSIAEULYHiXXIDAs9iTSdYAw1gmjBUkVWnVoyaolts33jslcJWPCAzmbJyLlGgag9jGHp32ax69NedXDBB4RNvuN4CfdjqD9GDpKoK1buHEFN2/gcgjMX4C5c23Sq+5F0dhwQKI7EbQWhuz0YhAshcBCoFeshMFqGHj84CCOR4/ttLrEWr+Ebp8249PYtO4zbbZfMyZOu7t3aOyfna7YMTOKIShGVjEyfRe+zbisXYutZ4X8AhCrG889AJn/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Twitter DM Fund",
          "title": "Twitter DM Fund",
          "src": "/static/d8249a10fbacde136968ea15b1208e78/31401/twitter-dm-fund.png",
          "srcSet": ["/static/d8249a10fbacde136968ea15b1208e78/d9f49/twitter-dm-fund.png 400w", "/static/d8249a10fbacde136968ea15b1208e78/31401/twitter-dm-fund.png 450w"],
          "sizes": "(max-width: 450px) 100vw, 450px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Okay, now we at least know something….and can tell if they might be a good fit for the fund.`}</p>
    <p>{`Next, we needed to know if they’re an accredited investor or not. Instead of directly asking, we go with “do you know if you qualify to be an accredited investor?”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "408px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4klEQVQoz32SS2tTQRTHs/IbuBBciW6KCx/YlZYq0q07EQQLakSra937IUTqowaLpSm0seCi1iZtQDeiINrGR2pvbm6SJrG5d2bOzJ2ZOw8nqZBY0OE3f+bA/M8c5pyU/fcyPV2uJk82ZOarmCqJZyWZ6fF4Q35qq9TgbWWsUMYhtRU9Em0vLdHjs2R4jhybJafmyOl5cBydIRNrLDXo7MQJ4gpzFTIJQjGpXaIW09866kfoSJyWI7WJzSbSZaT75lgZFKsIQztEItGtdtvzvCAItuu17aDi/yw3qpWa79V9T3FmrY6E6Zu5MiB0o7Xz3Qu4VIhAtd70a41qo1lr/ooQcXQQCTFp7YQEaCjsX2bEJOUSYuHgMqEsxsBCt7kwZu9Hhrz38m4gtLn7ll1ZhnQerq7A9QK9uRbfLvKJIk8X4mt5emuVpfP0RoFdfk0vvIL773nfLLU5vwgnsnBmHkYW4PA03v8UHczgI9PYnQ89x0MvyNAMcXpgCu17GF1con/K3q1qNUgerYtMSbiuTn4RDz6LyXUx/oa6dOdycDYHowswmoOxxW54712c+t+Q9DLeKbKTWddeMtKtqNvnsZdkOIvHV9jeIRlEGqut7XCzhXWFaM8p1ltIffTDD34UEP0bslY99UDLk0wAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Twitter DM Fund 2",
          "title": "Twitter DM Fund 2",
          "src": "/static/29a57dcb450df4d8d7baba5a61401511/7d91d/twitter-dm-fund-2.png",
          "srcSet": ["/static/29a57dcb450df4d8d7baba5a61401511/d9f49/twitter-dm-fund-2.png 400w", "/static/29a57dcb450df4d8d7baba5a61401511/7d91d/twitter-dm-fund-2.png 408w"],
          "sizes": "(max-width: 408px) 100vw, 408px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
If the lead passed both of these tests, we knew to keep going!`}</p>
    <p><strong parentName="p">{`Then We Use The Deck:`}</strong><br parentName="p"></br>{`
`}{`Remember Day 3 - nailing our pitch deck? That was the ammo we stockpiled now that the battle has begun.`}</p>
    <p>{`Once someone has been qualified, we send them the deck to review.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "412px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABg0lEQVQoz12Ryy9DQRTGm0j8CSQSGyvCQkIEVc8Nwr4siJXEwr9CIhaIx0KIR29phaRE2AuxIPFsuNVLqTtzz3RubzsPc6sV7eTLyTcn+c18Z8YjixcvKCvUTrwTPhgm3gC0BcCnkQ5Vg9AaAK9GevaIpwS2KQJsEMsAknS4fKdi6twePab+CB2NqJoaiaTGTujEKZ08owVYuBfZ5OtjpdacK0/OeNBunyMlY1khRJaLDONKTEglFYpxaWflH8xdGMcS6014teZ7oQIO/cYX6Lr+8qrrsVg8HjdN83/GNCuGKXr9XqxMz5elZj1OsMthElDSQggQYpl0yYBqqKLYzIFbbfxiuftyqf1qf/qeyAfMHy2p9ID4nZnXvcmfMVedPMxdVhpUDh1lvHt2dyjdG7I7ck/q06BTg9ZdVYlXg64gadmBqlXcvA1F8BvhwwdkIJzqD5PGLajbgIZNqM9Jedds5H31mqWOKP2qT5s/YR7FbrCo5Zq8LLfz27xN0OsYvknQH/MOxoCs0B1gAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Twitter DM Fund 3",
          "title": "Twitter DM Fund 3",
          "src": "/static/0841f257f4af51f6103568efe6c98b31/1f9f1/twitter-dm-fund-3.png",
          "srcSet": ["/static/0841f257f4af51f6103568efe6c98b31/d9f49/twitter-dm-fund-3.png 400w", "/static/0841f257f4af51f6103568efe6c98b31/1f9f1/twitter-dm-fund-3.png 412w"],
          "sizes": "(max-width: 412px) 100vw, 412px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Initially I would say this^ "let me know if you have any questions"...but this felt a little weak. I was basically just asking them to come up with hesitations/objections. That seemed...bad.`}</p>
    <p>{`So I switched it up and got much better results being more direct:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "606px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.24999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4UlEQVQoz22QzU8TQRiHm+idHpWEIxEtQvyIlIuRiweNiUo4mXDwI36e1L/BRhE4EO4bXLorWw6YaBRNBSJ6MNiCJ0hp6RbaKG3Tne/ZZWec3aaIiW+ewy/vzDN534lIKWtMjK3yxA/+PMMTGT6S5S+z/EXIyIEwvsaffmOX35Lr78nAHFb3I0q2cm6H5vSnYJ8Fj+mg10QnTdRtoE4d9oThhIFOzQTNsxaKp9DFN6RdgzfTJJC/V+WFd/LKvLz6SfbNyeMmiyVhzECxUGsF3G3iriTunEZdSXREQ4+XWSDTuv1zenhl6kZGH85qQxu5VduTRewXidyngGQe/iUHZAnLQK5upBujbXSynUwcxeNRlpsnzKewwTCkCFKMEGh4riv/LeCFcr3wta7FQXLA0c87U/1o60vpt2OXtovb5WJpp1z5tVutMs7VTdFCldOUa+sfa88OgbGoMxptJA6zzQ97UjJKhOcK3xN7nvxfwaYMd/M7xmDFuFYxB8uvLtXtFRKc+WrPLSgKCuDnQRhabAJhYxFpDuMdgPnBw0+WmfrVMxaOp3DPa6zCuRQ+beHeGRyfVd+O7i/RSDiCkMLfxxfBUvq6eztNHyzSR0v0zucgPFykdxfovQXVYbfSdGKN/wHHAOHAFjHKTgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Twitter DM Fund 4",
          "title": "Twitter DM Fund 4",
          "src": "/static/f6d4c9ce39f9b067f9df505dd98eee52/cec09/twitter-dm-fund-4.png",
          "srcSet": ["/static/f6d4c9ce39f9b067f9df505dd98eee52/d9f49/twitter-dm-fund-4.png 400w", "/static/f6d4c9ce39f9b067f9df505dd98eee52/cec09/twitter-dm-fund-4.png 606w"],
          "sizes": "(max-width: 606px) 100vw, 606px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Meanwhile - I was Creating FOMO`}</strong></p>
    <p>{`While I was slowly going through DMs, I would periodically tweet updates to make it seem like the response was off the charts (which it was!)`}</p>
    <p>{`As soon as we closed our first money..`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXElEQVQoz3VRTUvDQBDtzxYEf4BQQQRFDx4LHkSkID148SQKpRQs2FSbmibpJtmkm+z3bj6cWNQKdngMb3f27bzZ7ewdno5n3tNw/OK4Qzd9drET0YQAmDAVU5ZK8y9yrjrdk/NXD40mznTujubh43s68bMoJQiTZF1gQnfpC6E7UEVJ5vpRGHzk2A1Q7PlBGGGAuwxQkkpTcW2FtpssdLnJcGmHCM2kEcpKZeumKcvK2NLaEkhdt0utjTH2JyutVZuN1CBmigpFueJCNU2TFxRFOF2TPKckLxKcoSiB9QrFWZYDD1dxFKfgjVC+EWsmNJcaxIwL0MNpjDPo3+wOMPgrBsAWQsnsbeEufG+5graMcaU0+ADTUK2/A6j+I+at7bvBQ++qf30zuO3fHx1fnl30gOwfdKfOHKrtM2x3hu+CR8+/Jq/qCiaXylAusnXBhAQAB2g4W1XbkMZ+AogZ+Cb+yPgbAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan Tweet First Close",
          "title": "Shaan Tweet First Close",
          "src": "/static/66c23884d9d374274e573ac57dd2c5dc/0f09e/shaan-tweet-first-close-money.png",
          "srcSet": ["/static/66c23884d9d374274e573ac57dd2c5dc/d9f49/shaan-tweet-first-close-money.png 400w", "/static/66c23884d9d374274e573ac57dd2c5dc/0f09e/shaan-tweet-first-close-money.png 640w"],
          "sizes": "(max-width: 640px) 100vw, 640px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The next day the DMs had slowed down, and the twitter world had moved on...so I crafted a new tweet to re-bump the thread. This is part of my general strategy of "working in public".`}</p>
    <p>{`This stuff seems obvious, but most folks keep everything quiet / close to the vest. There is a time and place for that, but`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "663px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQoz5VSyW7UQBCd34Yc+AJuI3KGCwJuUS4hEUjDMiEjSDJSmMVjzxbvWzy22+7N5rV7JISYSFBql6ra9eq9ru7exFw/e94f3hmz6eTnZGqtNlvH84LQC6IwTihjnIv2kAkpe+OZ+fTJ0Wg8TZLYdT3X8xx8nu8HYRhGWGVZto9Yr1HWciEiWByFUZSkKdCO4wKpi5pDtgdDALQlSRrHcMqrQIUp3G6306WHmYVsOOfgDAI45UEbBAFidRDXy/OiqipCqjzPERRliWQPlk1TErLebLbb7XqzNRamaS3nhmEtV8vVCunCtLAzmxvT2dy0LGOxwN+iKHpa/kOWoQJ197ZDKmWYE6gI0RmsRkgprWusGmlNaa/phs6EZFygjT7dI2f8w0DayZZNRTlFAyl9P7KdIEmzKE6zXe4HSL0wSjAExK4X3tu4ztB2/CTJwAywrJkCo1FREMjN87IoCaV4Ibx7JgJeCNW9c1IHv8GsY46TBzCsNjaogPwX2bKmnHEJqrfvTk9OL87eDwafr84vPvWPX71+c3J2Pui/eGnbvsYcAjPoEsPL69vx5Prm7tvoZnj5Ay2+fP0+Gt1++DhM0+wvcNsCjANjtf9jGqxeGIaCW8QGhkMqTIB3clhJKjUuIeqa4VKarkDqaC8bW0LgwLpfVVP8RowWiHUdBq8JmQLvlf8CuM6MvOpS8ggAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan Tweet First Close-2",
          "title": "Shaan Tweet First Close-2",
          "src": "/static/15a2bd76783e72a6da4db302f913b197/5c8e6/shaan-tweet-first-close-money-2.png",
          "srcSet": ["/static/15a2bd76783e72a6da4db302f913b197/d9f49/shaan-tweet-first-close-money-2.png 400w", "/static/15a2bd76783e72a6da4db302f913b197/5c8e6/shaan-tweet-first-close-money-2.png 663w"],
          "sizes": "(max-width: 663px) 100vw, 663px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Random Tangent Story INCOMING -`}</strong></p>
    <p>{`When we were selling our company, we were in negotiations with a bunch of big tech companies. In order to create a bit of fomo there, I did something hilarious.`}</p>
    <p>{`First, I added all the Corp Dev / M&A people from each company on instagram`}</p>
    <p>{`Normally, I never post stories. I'm more of a twitter guy than instagram. But duirng the month of the sale, I started posting little hints. Like a selfie in front of a company's headquarters with the logo slightly showing… Or you know that little Airplane Map thing where it shows you have 6 hours to your destination? I would post a pic of that map, clearly showing that I was flying to Seattle (oh, a few companies happen to have offices there)...`}</p>
    <p>{`I hvae no idea if the corp dev people ever saw this, but if they did, I wanted to subtly clue them in that I was having convos with their competitors. This is some art of war shit.`}</p>
    <p>{`I thought I was the only crazy person to do thigns like this, then I talked to a friend who sold a company for $1B and he told me they opened up a second office in london and hired a ton of engineers right before the sale, because "it made us look big/growing to open up a london office" LOL`}</p>
    <p><strong parentName="p">{`/ end of random tangent`}</strong></p>
    <p>{`  `}<strong parentName="p">{`OK now the most important bit - going for the close`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "636px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "35%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIElEQVQY032Oy0rDUBCGzwu4defGKu50oSBW6w1MsdYHcOHel/ANBB+gIBQjbdOmaS64UBduu9GCuDI2QrcqNDO5cJI0zomIFC/Dx8/PMJefPbzGnT6vPfEGYXPl02Qo9hjNTBtfZgAJK1vefB0kHddU3NBwuQmSgasqbGq40oK8CuSXFKFbGi4qUFCBZiYqw6Nbn5UsnLuAooE0vd0RC3SobGFexamqOyO7OeI8U9mdlkVnVnYnz4aH1z6j2KYjkrSfv2nZ3HD4yX24Y6A0DnWKJq5rcNwNWPpbxYnQ0164UIc9yyu0kaKVTI9C7Vre/mWQk/HgymfxKI1+wLPlASZan+v9SHcioZmpPbrV3lvl7v3m5Y/P/1XCRzxIo5DcB6y1Syak/Q/QAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan DM 99 Spots",
          "title": "Shaan DM 99 Spots",
          "src": "/static/19127f32ff7facd7d2957ce196c97bff/44bb2/shaan-dm-99-spots.png",
          "srcSet": ["/static/19127f32ff7facd7d2957ce196c97bff/d9f49/shaan-dm-99-spots.png 400w", "/static/19127f32ff7facd7d2957ce196c97bff/44bb2/shaan-dm-99-spots.png 636w"],
          "sizes": "(max-width: 636px) 100vw, 636px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This worked. We signed up 40 LPs immediately.`}</p>
    <p>{`I can't teach you how to create a wave like this - except maybe: I hustled for a year, did 100+ podcasts, published them for free - and built up a loyal twitter following.`}</p>
    <p>{`This post is about surfing the wave. Taking advantage of when the opportunity arises.`}</p>
    <p>{`That’s what I call riding the wave`}</p>
    <p><strong parentName="p">{`I also want to highlight some things we DIDN'T do.`}</strong></p>
    <p>{`Focus on anything else - when the wave hits, you drop everything else. Even the all access pass, we were a day late on an update. Worth it.`}</p>
    <p>{`We didn't waste time on unqualified leads.`}</p>
    <p>{`We didn't waste time setting up follow-up meetings or calls when we sensed that people were ready. I did a couple facetime calls (so people could "see who they were in business with"), but 90% of the money closed via Twitter DMs.`}</p>
    <p>{`This worked partially because people already felt like they knew me via the podcast. But also because we were publishing tweets showing how much momentum the fund had. People want to be a part of something big.`}</p>
    <p><strong parentName="p">{`So what's next? We move from "warm leads" to our hardest challenge: "cold leads", and I'll also share learnings from my first couple startup pitch meetings with the new fund..`}</strong></p>
    <PostFooter prevButton={{
      text: postMap.going_down_the_funnel.label,
      slug: slugify(postMap.going_down_the_funnel.slug)
    }} nextButton={{
      text: postMap.sometimes_you_need_to_be_a_worker_ant.label,
      slug: slugify(postMap.sometimes_you_need_to_be_a_worker_ant.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      